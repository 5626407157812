import React, { useState} from 'react';
import { View, Text, TextInput, TouchableOpacity, Image, StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useFonts } from 'expo-font';

const Page = () =>{
    const [activeTab, setActiveTab] = useState(0);
  const [fontsLoaded] = useFonts({
    'Cut-the-crap': require('../assets/fonts/Cutthecrap.ttf'),
  });
  const handleTabPress = (index) => {
    setActiveTab(index);
  };

return(
<View style={styles.container}>

<View style={styles.topContainer}>
<View style={styles.trendContainer}>
    <Text style={styles.trend}>Trend</Text>
</View>
<View style={styles.iconeContainer}>
<Ionicons name="notifications-outline" size={20} color="black" style={styles.icon} />
<Ionicons name="settings-outline" size={20} color="black" style={styles.icon} />
</View>
</View>
<View style={styles.tab}>
        <TouchableOpacity
          style={[styles.tabLink, activeTab === 0 && styles.activeTabLink]}
          onPress={() => handleTabPress(0)}
        >
          <Text style={styles.tabText}>MES ACTIVITÉS</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.tabLink, activeTab === 1 && styles.activeTabLink]}
          onPress={() => handleTabPress(1)}
        >
          <Text style={styles.tabText}>MODEPRO</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.tabLink, activeTab === 2 && styles.activeTabLink]}
          onPress={() => handleTabPress(2)}
        >
          <Text style={styles.tabText}>PARCOURS MYSTÈRE</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.tabLink, activeTab === 3 && styles.activeTabLink2]}
          onPress={() => handleTabPress(3)}
        >
          <Text style={styles.tabText2}>ACCES VIP</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.tabContent}>
        {activeTab === 0 && <Text style={styles.tabText}>1</Text>}
        {activeTab === 1 && <Text style={styles.tabText}>2</Text>}
        {activeTab === 2 && <Text style={styles.tabText}>3</Text>}
        {activeTab === 3 && <Text style={styles.tabText2}>4</Text>}
      </View>
</View>

);
}
const styles = StyleSheet.create({
container:{
 paddingTop:40,
 padding:30,
 backgroundColor:'purple',
 height:'100%'
    },
topContainer:{
    marginTop:10,
    flexDirection:'row'
},
trendContainer:{
width:'80%',
},
trend:{
    textAlign:'center',
    color:'orange',
    fontSize:40,
    fontFamily:'Cut-the-crap',
},
iconeContainer:{
    width:'20%',
    flexDirection:'row',
},
icon: {
    fontSize: 25,
    color: 'white',
    margin: 5,
  },
MODEPRO:{
  color:'orange',
  fontSize:25,
  textAlign:'center',
  width:'80%',
  marginTop:100
},
iconeContainer2:{
    color:'orange',
    flexDirection:'row',
    justifyContent:'space-evenly',
    borderBottomColor:'orange',
    borderBottomWidth: 2,
    marginTop:50,
},
orangeColor:{
    color:'orange',
    padding:10,
    fontSize:20
},
yellowColor:{
    color:'yellow',
    padding:10,
    alignContent:'space-between',
    fontSize:20
    
},
tab: {
    flexDirection: 'row',
    marginVertical:40,
  },
  tabLink: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: 10,
    borderBottomWidth: 2,
    borderBottomColor: 'transparent',
  },
  activeTabLink: {
    borderBottomColor: 'orange',
  },
  activeTabLink2: {
    borderBottomColor: 'yellow',
  },
  tabText: {
    fontSize: 15,
    fontWeight: 'bold',
    color:'orange',
    fontFamily:'Cut-the-crap',
  },
  tabText2: {
    fontSize: 15,
    fontWeight: 'bold',
    color:'yellow',
    fontFamily:'Cut-the-crap',
  },
  tabContent: {
    marginTop: 20,
    alignItems:'center'
  },
});


export default Page;
