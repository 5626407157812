import React from 'react';
import { View, Text, TextInput, StyleSheet,TouchableOpacity } from 'react-native';
import { MaterialIcons, Ionicons } from '@expo/vector-icons';
import { Dimensions } from 'react-native';
import { useFonts } from 'expo-font';
import { useNavigation } from '@react-navigation/native';
// import MapView from 'expo-map-view';

const windowWidth = Dimensions.get('window').width;

const Map = () => {
  const navigation = useNavigation();
  // const [location, setLocation] = useState(null);

  // useEffect(() => {
  //   // Request permission to access user's location
  //   (async () => {
  //     const { status } = await Location.requestForegroundPermissionsAsync();
  //     if (status !== 'granted') {
  //       console.log('Permission to access location was denied');
  //       return;
  //     }

  //     // Get user's current location
  //     const { coords } = await Location.getCurrentPositionAsync({});
  //     setLocation(coords);
  //   })();
  // }, []);
  const [fontsLoaded] = useFonts({
    'Cut-the-crap': require('../assets/fonts/Cutthecrap.ttf'),
  });
  return (
    <View style={styles.container}>
      <View style={styles.topBar}>
        <View style={styles.containerTitle}>
        <TouchableOpacity onPress={() => navigation.navigate('Dashboard')}>
          <Text style={styles.title}>TREND</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.containerStar}>
          <Ionicons name="chatbubble-outline" style={[ windowWidth > 600 ? styles.rowicon : styles.columnicon]} />
          <Ionicons name="notifications-outline" style={[ windowWidth > 600 ? styles.rowicon : styles.columnicon]} />
          <Ionicons name="settings-outline" style={[ windowWidth > 600 ? styles.rowicon : styles.columnicon]} />
        </View>
      </View>
      <View style={styles.act}>
      <View style={styles.mes}>
      <TouchableOpacity onPress={() => navigation.navigate('Tab')}>
          <Text style={styles.navLink}>MES ACTIVITÉS</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate('ParcoursMystere')}>
          <Text style={styles.navLink}>PARCOURS</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate('Dashboard')}>
          <Text style={styles.navLink}>BOUTIQUE</Text>
          </TouchableOpacity>
        </View>
          <View style={styles.containerRecherche}>
            <View style={styles.combine}>
              <TextInput style={styles.input} placeholder="EXPLORER" />
              <MaterialIcons name="search" style={styles.searchIcon} />
            </View>
          </View>
       
      </View>
      <View style={[styles.gridContainer, windowWidth > 600 ? styles.rowContainer : styles.columnContainer]}>
        <View style={styles.gridItem}>
        {/* <MapView
        style={styles.map}
        initialRegion={{
          latitude: location?.latitude || 0,
          longitude: location?.longitude || 0,
          latitudeDelta: 0.0922,
          longitudeDelta: 0.0421,
        }}
      >
      </MapView> */}
        </View>
        <View style={styles.gridItem}>
          <View style={styles.card}>
          <TouchableOpacity onPress={() => navigation.navigate('Dashboard')}>
            <Text style={styles.act}>Localisation (ville) Météo</Text>
            <View style={styles.arrw}>
              <MaterialIcons name="keyboard-arrow-right" style={styles.arrwIcon} />
            </View>
            </TouchableOpacity>
          </View>
          <View style={styles.card}>
          <TouchableOpacity onPress={() => navigation.navigate('Dashboard')}>
            <Text style={styles.act}>Actualité locale</Text>
            <View style={styles.arrw}>
              <MaterialIcons name="keyboard-arrow-right" style={styles.arrwIcon} />
            </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    padding:20
  },
  topBar: {
    flexDirection: 'row',
    padding: 10,
  },
  containerTitle: {
    flex: 1,
  },
  title: {
    fontSize: 50,
    color: '#ff9f2f',
    padding: 20,
    fontFamily:'Cut-the-crap',
  },
  containerStar: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  rowicon: {
    marginRight: 10,
    fontSize:45,
  },
  columnicon: {
    marginRight: 10,
    fontSize:35,
  },
  act: {
    marginTop: 20,
  },
  mes: {
    backgroundColor: 'rgb(178, 172, 184)',
    borderRadius: 25,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    fontSize: 25,
    color: 'white',
    padding: 2,
  },
  navLink: {
    margin: 10,
    color:'white',
    fontFamily:'Cut-the-crap',
    fontSize:20,
  },
  containerRecherche: {
    height: 50,
    marginTop: 50,
    shadowColor: 'rgba(0, 203, 255, 0.5)',
    shadowInset: { width: 4, height: 4 },
    shadowRadius: 5,
    borderRadius: 10,
  },
  input: {
    width: '100%',
    height: '100%',
    fontSize: 20,
    padding: 10,
    borderRadius: 10,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: 'rgba(0, 0, 0, 0)',
    fontFamily:'Cut-the-crap',
    color:'gray'
  },
  searchIcon: {
    position: 'absolute',
    top: '50%',
    fontSize: 30,
    right: 10,
    transform: [{ translateY: -15 }],
  },
  gridContainer: {
    justifyContent: 'space-between',
    alignContent:'center',
    flex:1
    
  },
  rowContainer: {
    flexDirection: 'row',
  },
  columnContainer: {
    flexDirection: 'column',
  },
  gridItem: {
    width: windowWidth > 600 ? '48%' : '100%',
    marginBottom: 10,
    alignItems: 'center',
  },
  map: {
    flex: 1,
    height: '90%',
    width: '100%',
  },
  card: {
    height: 200,
    marginTop: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
    justifyContent: 'center',
    padding: 10,
    width: windowWidth > 600 ? '48%' : '100%',
  },
  arrw: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  arrwIcon: {
    fontSize: 30,
  },
});

export default Map;
