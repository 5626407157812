import React, { useState} from 'react';
import { View, Text, TextInput, TouchableOpacity, Image, StyleSheet,ScrollView } from 'react-native';
import { Dimensions } from 'react-native';
import { useFonts } from 'expo-font';
import { boussole } from '../assets/images';
import { useNavigation } from '@react-navigation/native';

const windowWidth = Dimensions.get('window').width;

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, csetPassword] = useState('');
  const [mail, setMail] = useState('');
  const [age, setAge] = useState('');
  const [message, setMessage] = useState('');
  const handleRegistration = async () => {
    const formData = new FormData();
formData.append('username', username);
formData.append('password', password);
formData.append('cpassword', cpassword);
formData.append('mail', mail);
formData.append('age', age);

    try {
      // Make a POST request to the login endpoint with the username and password
      const response = await fetch('https://www.gethme.com/mspr.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData,
      });

      //Get parameters as such 
      // if ($_SERVER['REQUEST_METHOD'] == 'POST') {
      //   // Get username and password from the request body
      //   $username = $_POST['username'];
      //   $password = $_POST['password'];
      // }


      // Parse the JSON response
      const data = await response.json();

      if (data.success) {
        // If the login was successful, set the message to "Login successful"
        setMessage('Login successful');
        navigation.navigate("CodeVerif")
      } else {
        // If the login failed, set the message to the error message from the API
        setMessage(data.message);
      }
    } catch (error) {
      // If there was an error, set the message to the error message
      setMessage(error.message);
    }
  };
  const navigation = useNavigation();
    const [fontsLoaded] = useFonts({
      'Cut-the-crap': require('../assets/fonts/Cutthecrap.ttf'),
    });
    return (
      <View style={styles.container}>
      <Text style={[ windowWidth > 600 ? styles.rowtitle : styles.columntitle]}>OU EST-CE QU'ON VA AUJOURD'HUI?</Text>      
      <View style={[styles.gridContainer, windowWidth > 600 ? styles.rowContainer : styles.columnContainer]}>
      <ScrollView showsVerticalScrollIndicator={false}>
      <View style={styles.gridItem}>
          <Image source={boussole} style={[ windowWidth > 600 ? styles.rowImage : styles.columnImage]} resizeMode="contain" />
      </View>
        <View style={styles.gridItem}>
          <View style={styles.form}>
          <TouchableOpacity onPress={() => navigation.navigate('Dashboard')}>
            <Text style={styles.formHeading}>TREND</Text>
            </TouchableOpacity>
            <TextInput style={styles.input} placeholder="Adresse e-mail, Nom d'utilisateur"  value={mail} onChangeText={setMail} />
            <TextInput style={styles.input} placeholder="Nom complet" value={username} onChangeText={setUsername}/>
            <TextInput style={styles.input} placeholder="Mot de passe" secureTextEntry={true} value={password} onChangeText={setPassword}/>
            <TextInput style={styles.input} placeholder="Confirmation de mot de passe" secureTextEntry={true} value={cpassword} onChangeText={csetPassword} />
            <TextInput style={styles.input} placeholder="Tranche d'age" keyboardType="numeric" value={age} onChangeText={setAge} />
            <TouchableOpacity style={styles.button} onPress={handleRegistration}>
              <Text style={styles.buttonText}>S'inscire</Text>
            </TouchableOpacity>
            <Text>{message}</Text>
            
        </View>
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Login')}>
              <Text style={styles.buttonText}>Connectez-vous</Text>
            </TouchableOpacity>
      </View>
      </ScrollView>
    </View>

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    padding: 30,
  },
  rowtitle: {
    fontSize: 50,
    color: '#ff9f2f',
    textAlign: 'center',
    padding: 20,
    fontFamily:'Cut-the-crap',
    marginBottom:30,
  },
  columntitle: {
    fontSize: 35,
    color: '#ff9f2f',
    textAlign: 'center',
    padding: 20,
    fontFamily:'Cut-the-crap',
    
  },
  gridContainer: {
    justifyContent: 'space-between',
    alignContent:'center',
    flex:1
    
  },
  rowContainer: {
    flexDirection: 'row',
  },
  columnContainer: {
    flexDirection:'column-reverse',
  },
  gridItem: {
    width: windowWidth > 600 ? '48%' : '100%',
    marginBottom: 10,
    alignItems: 'center',
  },
  columnImage: {
    width: 400,
    height: 500,
  },
  rowImage:{
    width: '80%',
    height: '80%',
  },
  heading: {
    fontSize: 30,
    fontWeight: 'bold',
    color: '#fdfdfd',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
    
  },
   
  form: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 25,
    paddingVertical: 40,
    backgroundColor: 'darkgrey',
    marginBottom: 10,
    width: '80%',
    alignItems: 'center',
  },
  formHeading: {
    fontSize: 50,
    fontWeight: 'bold',
    color: '#fdfdfd',
    textAlign: 'center',
    marginBottom: 20,
    fontFamily:'Cut-the-crap',
  },
  input: {
    marginBottom: 20,
    padding: 10,
    borderRadius: 10,
    backgroundColor: 'darkgrey',
    borderColor: 'white',
    borderWidth: 2,
    color: 'white',
    alignItems: 'center',
    width: '80%',
  },
  button: {
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: 'darkgrey',
    borderWidth: 2,
    borderColor: 'white',
    alignItems: 'center',
    width: '60%',
    padding:10,
  },
  buttonText: {
    color: 'white',
  },
  signupButton: {
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: 'darkgrey',
    borderColor: 'white',
    borderWidth: 2,
    fontSize: 18,
    padding: 10,
  },
  signupButtonText: {
    color: 'white',
    textAlign: 'center',
  },
  
});


export default Register;
