import React from 'react';
import { View, Text, TextInput, StyleSheet,TouchableOpacity, Dimensions } from 'react-native';
import { Ionicons,MaterialIcons } from '@expo/vector-icons';
import { useFonts } from 'expo-font';
import { useNavigation } from '@react-navigation/native';

const windowWidth = Dimensions.get('window').width;

const Dashboard = () => {
  const navigation = useNavigation();
  const [fontsLoaded] = useFonts({
    'Cut-the-crap': require('../assets/fonts/Cutthecrap.ttf'),
  });

  
  return (
    <View style={styles.container}>
      <View style={styles.topBar}>
        <View style={styles.containerCircle}>
          <TouchableOpacity onPress={() => navigation.navigate('Map')}>
         <Ionicons name="ellipsis-horizontal-circle-outline" style={[ windowWidth > 600 ? styles.rowicon : styles.columnicon]} />
      </TouchableOpacity>
        </View>
        <View style={styles.titleContainer}>
        <TouchableOpacity onPress={() => navigation.navigate('Dashboard')}>
          <Text style={styles.title}>TREND</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.containerCircle2}>
        <TouchableOpacity onPress={() => navigation.navigate('Interets')}>
          <Ionicons name="star-outline" style={[ windowWidth > 600 ? styles.rowicon : styles.columnicon]} />
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.position}>
        <Text style={styles.positionText}>LOCALISATION (VILLE)</Text>
      </View>
      <View style={{ alignItems:'center' }}>
      <View style={styles.containerRecherche}>
              <TextInput style={styles.input} placeholder="EXPLORER" />
              <MaterialIcons name="search" style={styles.searchIcon} />
          </View>
      
      
      
      
      <View style={styles.card}>
      <TouchableOpacity onPress={() => navigation.navigate('Tab')}>
        <Text style={styles.activity}>MES ACTIVITÉS</Text>
        <View style={styles.arrowContainer}>
          <Ionicons name="chevron-forward" size={25} />
        </View>
        </TouchableOpacity>
      </View>
      
      <View style={styles.card}>
      <TouchableOpacity onPress={() => navigation.navigate('Map')}>
        <Text style={styles.activity}>MODE GUIDE</Text>
        <View style={styles.arrowContainer}>
          <Ionicons name="chevron-forward" size={25} />
        </View>
        </TouchableOpacity>
      </View>
      <View style={styles.card}>
      <TouchableOpacity onPress={() => navigation.navigate('ParcoursMystere')}>
        <Text style={styles.activity}>PARCOURS MYSTÈRE</Text>
        <View style={styles.arrowContainer}>
          <Ionicons name="chevron-forward" size={25} />
        </View>
        </TouchableOpacity>
      </View>
      <View style={styles.rectangle}>
      <TouchableOpacity onPress={() => navigation.navigate('Login')}>
        <Text style={styles.activity}>Profile</Text>
        <View style={styles.upContainer}>
          <Ionicons name="chevron-up" size={30} />
        </View>
        </TouchableOpacity>
      </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  containerCircle: {
    width: '15%',
    alignItems:'center',
    justifyContent:'center'
  },
  containerCircle2: {
    width: '15%',
    justifyContent:'center',

    alignItems:'center'
  },
  
 
  rowicon: {
    marginRight: 10,
    fontSize:45,
  },
  columnicon: {
    marginRight: 10,
    fontSize:35,
  },
  container: {
    flex: 1,
    backgroundColor:'white'
  },
  topBar: {
    backgroundColor: 'orange',
    paddingHorizontal: 20,
    paddingTop:50,
    paddingBottom:10,
    borderBottomColor:'gray',
    borderBottomWidth:1,
    flexDirection: 'row',
  },
  circle: {
    padding:10,
    width:40,
    height:'100%',
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleContainer: {
    width: '70%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: 'white',
    fontSize: 40,
    fontFamily:'Cut-the-crap',
    alignItems:'center'
  },
  position: {
    backgroundColor: 'orange',
    padding: 10,
    shadowColor: 'orange',
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 1,
    shadowRadius: 20,
    marginBottom: 50,
  },
  positionText: {
    fontSize: 40,
    fontFamily:'Cut-the-crap',
    color:'white'
  },
  card: {
    height: 125,
    margin: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: 'black',
    fontSize: 20,
    width:'70%',
    padding:5
  },
  activity: {
    fontSize: 20,
    color:'gray',
    fontFamily:'Cut-the-crap',

  },
  arrowContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    
  },
  rectangle: {
    height: 100,
    margin: 10,
    borderRadius: 20,
    borderWidth: 1,
    borderBottomWidth:0,
    borderColor: 'black',
    fontSize: 20,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: -30 },
    shadowOpacity: 0.5,
    marginTop: 50,
    width:'70%',
    padding:5
  },
  upContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    fontSize: 30,
  },
  containerRecherche: {
    width:'70%',
    margin: 10,
    shadowColor: 'rgba(0, 0, 0, 0.5)',
    shadowInset: { width: -4, height: 4 },
    shadowRadius: 5,
    borderRadius: 10,
    borderWidth:1,
  },
  input: {
    fontSize: 20,
    padding: 10,
    borderRadius: 10,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: 'rgba(0, 0, 0, 0)',
    fontFamily:'Cut-the-crap',
    color:'gray'
  },
  searchIcon: {
    position: 'absolute',
    top: '50%',
    fontSize: 30,
    right: 10,
    transform: [{ translateY: -15 }],
  },
});

export default Dashboard;
