import { View, Text, SafeAreaView, Image, TouchableOpacity } from 'react-native'
import React, { useLayoutEffect } from 'react'
import * as Animatable from 'react-native-animatable';
import { useNavigation } from '@react-navigation/native'
import { HeroImage } from '../assets';

const HomeScreen = () => {
    const navigation = useNavigation();

    useLayoutEffect(()=>{
        navigation.setOptions({
            headerShown: false,
        });
    },[]);

  return (
    <SafeAreaView className="bg-white flex-1 relative">
        {/* First-Section */}
       <View className="flex-row px-6 mt-8 items-center space-x-2">
        <View className="w-16 h-16 bg-red-400 rounded-full items-center justify-center">
            <Text className="text-white text-3xl font-semibold">G</Text>
        </View>
        <Text className="text-[#2A2B4B] text-3xl font-semibold">GethME</Text>
       </View>
       {/* Second-Section */}
       <View className="px-6 mt-8 space-y-3">
        <Text className="text-[#2A2B4B] text-[42px]">Enjoy the trip with</Text>
        <Text className="text-red-400 text-[38px] font-bold">Good Moments</Text>
        <Text className="text-base">lorem</Text>
       </View>

       {/* Circle-section */}
       <View className="w-[400px] h-[400px] bg-red-300 rounded-full absolute bottom-36 -right-36">     
       </View>
       <View className="w-[400px] h-[400px] bg-red-400 rounded-full absolute -bottom-28 -left-28"> 
       </View>

       {/* Image-Container */}
       <View className="flex-1 relatve items-center justify-center">
        <Animatable.Image
        animation="fadeIn" easing="ease-in-out"
        source={HeroImage} className="w-full h-full object-cover mt-20"/>
        <TouchableOpacity onPress={() => navigation.navigate("Form")} 
        className="absolute bottom-20 w-24 h-24 border-r-2 border-l-2 border-t-4 border-white rounded-full items-center justify-center">
        <Animatable.View 
        animation ={"pulse"}
        easing="ease-in-out"
        iterationCount = {"infinite"}
        className="w-20 h-20 items-center justify-center rounded-full bg-white">
            <Text className="text-red-400 text-[36px] font-semibold">Go</Text>
        </Animatable.View>
        </TouchableOpacity>
        
       </View>
       <View>
       {/* <TouchableOpacity onPress={() => navigation.navigate("Discover")} 
        className="absolute bottom-20 w-24 h-24 border-r-2 border-l-2 border-t-4 border-white rounded-full items-center justify-center">
        <Animatable.View 
        animation ={"pulse"}
        easing="ease-in-out"
        iterationCount = {"infinite"}
        className="w-20 h-20 items-center justify-center rounded-full bg-red-400">
            <Text className="text-gray-50 text-[36px] font-semibold">AR</Text>
        </Animatable.View>
        </TouchableOpacity> */}
       </View>
       <View>
       {/* <TouchableOpacity onPress={() => navigation.navigate("Form")} 
        className="absolute bottom-60 w-24 h-24 border-r-2 border-l-2 border-t-4 border-white rounded-full items-center justify-center">
        <Animatable.View 
        animation ={"pulse"}
        easing="ease-in-out"
        iterationCount = {"infinite"}
        className="w-20 h-20 items-center justify-center rounded-full bg-red-400">
            <Text className="text-gray-50 text-[36px] font-semibold">Login</Text>
        </Animatable.View>
        </TouchableOpacity> */}
       </View>
       
    </SafeAreaView>
  )
}

export default HomeScreen
