import React from 'react';
import { View, Text, TextInput, TouchableOpacity, Image, StyleSheet } from 'react-native';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { useFonts } from 'expo-font';
import { useNavigation } from '@react-navigation/native';

const ModePro = () =>{
    
    const navigation = useNavigation();
    const [fontsLoaded] = useFonts({
        'Cut-the-crap': require('../assets/fonts/Cutthecrap.ttf'),
      });
return(
<View style={styles.container}>

<View style={styles.topContainer}>
<View style={styles.trendContainer}>
<TouchableOpacity onPress={() => navigation.navigate('Dashboard')}>
          <Text style={styles.trend}>TREND</Text>
          </TouchableOpacity>
</View>
<View style={styles.iconeContainer}>
<Ionicons name="notifications-outline" style={[ windowWidth > 600 ? styles.rowicon : styles.columnicon]} />
<Ionicons name="settings-outline" style={[ windowWidth > 600 ? styles.rowicon : styles.columnicon]} />
</View>
</View>

<View>
    <Text style={styles.MODEPRO}>
    MODE PRO
    </Text>
</View>
<View>
<MaterialIcons name="casino" size={50} color="orange" />
</View>
</View>

);
}
const styles = StyleSheet.create({
container:{
 marginTop:40,
 padding:40,
    },
topContainer:{
    
    flexDirection:'row'
},
rowicon: {
    marginRight: 10,
    fontSize:45,
  },
  columnicon: {
    marginRight: 10,
    fontSize:35,
  },
trendContainer:{
width:'80%',
},
trend:{
    color: 'orange',
    fontSize: 40,
    fontFamily:'Cut-the-crap',
},
iconeContainer:{
    width:'20%',
    flexDirection:'row',
},
icon: {
    fontSize: 25,
    color: 'black',
    margin: 5,
  },
MODEPRO:{
    margin:20,
    color: 'orange',
    fontSize: 35,
    fontFamily:'Cut-the-crap',
}
});


export default ModePro;
