import React, { useState, useEffect, useLayoutEffect } from 'react';
import { SafeAreaView, ScrollView, Text, View, StyleSheet, FlatList, Image, TouchableOpacity  } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { WebView } from 'react-native-webview';
import { HouseImage, arpage } from '../assets';
import Discover from './Discover';
import Menu from '../components/Menu';
const styles = StyleSheet.create({
  container: {
    flex: 1,
    
  },
  row: {
    flexDirection: 'row',
  },
  item: {
    width: 150,
  },
  button: {
    padding: 10,
    borderRadius: 8,
    margin: 8,
  },
  
});

function Products() {
  
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch('https://gethme.com/api.html')
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch((error) => {
        // Handle any errors that occurred during the fetch
        console.error(error);
      });
  }, []);
  const navigation = useNavigation();

    useLayoutEffect(()=>{
        navigation.setOptions({
            headerShown: false,
        });
    },[]);
  const menuItems = ['Back','Home', 'Connect'];

  const handleSelectItem = (item) => {
    if(item=='Back'){
    
    navigation.goBack();
    }
    else{
      navigation.navigate("Form");
    }
   // console.log('Selected item:', item);
    // Handle the selected item here
  };
  
  const renderProduct = ({ item }) => {
    return (   <View className="mt-5">
          
      {products.map(product => (
        <View key = {product.id} style={styles.item} className="m-7 p-3 bg-slate-200">
      <View  className="shadow">
      <Text  className=" text-xl bg-red-400 p-2 text-white text-center">{product.landlord_name}</Text>
      <Text></Text>
      <Image source={{url:'https://gethme.com/images/1.jpg'}} style={{ width: 125, height: 50 }}/>
      <Text></Text>
      
      {/* <Text>Bedroom: {product.bedroom}</Text>
      <Text>Toilet: {product.toilet}</Text>
      <Text>Kitchen: {product.kitchen}</Text> */}
      <Text>Déscription: {product.comment}</Text>
      <Text></Text>
      <Text className=" text-x">Price: {product.price} FCFA</Text>
      </View>
      <Text></Text>
      <TouchableOpacity style={styles.button} onPress={() => navigation.navigate("Form")}>
        <Text className="bg-red-400 p-2 text-white text-center" style={styles.buttonText} > Contact</Text>
      </TouchableOpacity>
      </View>
      
    ))}
    
</View>);};
  return (
    
    // <View className="bg-white relative">
       <View style={styles.container} className="bg-red-400">
        <View className="mt-14">
      <Menu items={menuItems} onSelectItem={handleSelectItem}  />
    </View>
      <FlatList
        data={products}
        renderItem={renderProduct}
        keyExtractor={(item) => item.id.toString()}
        numColumns={2}
        columnWrapperStyle={styles.columnWrapper}
      />
    </View>
    // </SafeAreaView>
  );
}

export default Products;
