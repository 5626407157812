import React, { useState } from 'react';
import { View, Text, Image, Button, TouchableOpacity, StyleSheet } from 'react-native';
import { boussole } from '../assets/images';
import { Dimensions } from 'react-native';
import { useFonts } from 'expo-font';
import * as ImagePicker from 'expo-image-picker';
import { useNavigation } from '@react-navigation/native';


const windowWidth = Dimensions.get('window').width;




const ProfilePicture = () => {
  const navigation = useNavigation();

  const [selectedFile, setSelectedFile] = useState(null);

  const selectImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status === 'granted') {
      const result = await ImagePicker.launchImageLibraryAsync({ mediaTypes: ImagePicker.MediaTypeOptions.Images });
      if (!result.cancelled) {
        setSelectedFile(result.uri);
      }
    }
  };
  const handleProfilePicture = async () => {
    const formData = new FormData();
    formData.append('selectedFile', {
      uri: selectedFile,
      name: 'profile.jpg',
      type: 'image/jpg',
    });
    try {
      // Make a POST request to the login endpoint with the username and password
      const response = await fetch('https://www.gethme.com/mobilecollection.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body: formData,
      });

      //Get parameters as such 
      // if ($_SERVER['REQUEST_METHOD'] == 'POST') {
      //   // Get username and password from the request body
      //   $username = $_POST['username'];
      //   $password = $_POST['password'];
      // }


      // Parse the JSON response
      const data = await response.json();

      if (data.success) {
        // If the login was successful, set the message to "Login successful"
        setMessage('Login successful');
        navigation.navigate("ComptePro")
      } else {
        // If the login failed, set the message to the error message from the API
        setMessage(data.message);
      }
    } catch (error) {
      // If there was an error, set the message to the error message
      setMessage(error.message);
    }
  };
  const [fontsLoaded] = useFonts({
    'Cut-the-crap': require('../assets/fonts/Cutthecrap.ttf'),
  });
  return (
    <View style={styles.container}>
      <Text style={[ windowWidth > 600 ? styles.rowtitle : styles.columntitle]}>OU EST CE QU'ON VA AUJOURD'HUI?</Text>
      <View style={[styles.gridContainer, windowWidth > 600 ? styles.rowContainer : styles.columnContainer]}>
      <View style={styles.gridItem}>
          <Image source={boussole} style={[ windowWidth > 600 ? styles.rowImage : styles.columnImage]} resizeMode="contain" />
        </View>
        <View style={styles.gridItem}>
          <View style={styles.form}>
            <Text style={styles.formHeading}>TREND</Text>
            <Text style={styles.buttonText}>Nous y voila !</Text>
            <Text style={styles.buttonText}>Ajoutez une photo de profil à votre compte</Text>
            <TouchableOpacity style={styles.roundedBorder} onPress={selectImage}>
              <Text style={styles.buttonText}>image</Text>
            </TouchableOpacity>
            <View style={styles.roundedBordertwo}>
            {selectedFile && <Image source={{ uri: selectedFile }} style={{ width: 200, height: 200 }} />}
            </View>
            <TouchableOpacity style={styles.button} onPress={handleProfilePicture}>
              <Text style={styles.buttonText}>S'inscrire</Text>
            </TouchableOpacity>
            
          </View>
          <TouchableOpacity style={styles.button} onPress={() => console.log("Connectez-vous pressed")}>
              <Text style={styles.buttonText}>Connectez-vous</Text>
            </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    padding: 30,
  },
  rowtitle: {
    fontSize: 50,
    color: '#ff9f2f',
    textAlign: 'center',
    padding: 20,
    fontFamily:'Cut-the-crap',
    marginBottom:30,
  },
  columntitle: {
    fontSize: 35,
    color: '#ff9f2f',
    textAlign: 'center',
    padding: 20,
    fontFamily:'Cut-the-crap',
    
  },
  gridContainer: {
    justifyContent: 'space-between',
    alignContent:'center',
    flex:1
    
  },
  rowContainer: {
    flexDirection: 'row',
  },
  columnContainer: {
    flexDirection:'column-reverse',
  },
  gridItem: {
    width: windowWidth > 600 ? '48%' : '100%',
    marginBottom: 10,
    alignItems: 'center',
  },
  columnImage: {
    width: 400,
    height: 500,
  },
  rowImage:{
    width: '80%',
    height: '80%',
  },
  heading: {
    fontSize: 30,
    fontWeight: 'bold',
    color: '#fdfdfd',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
    
  },
   
  form: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 25,
    paddingVertical: 40,
    backgroundColor: 'darkgrey',
    marginBottom: 10,
    width: '80%',
    alignItems: 'center',
  },
  formHeading: {
    fontSize: 50,
    fontWeight: 'bold',
    color: '#fdfdfd',
    textAlign: 'center',
    marginBottom: 20,
    fontFamily:'Cut-the-crap',
  },
  input: {
    marginBottom: 20,
    padding: 10,
    borderRadius: 10,
    backgroundColor: 'darkgrey',
    borderColor: 'white',
    borderWidth: 2,
    color: 'white',
    alignItems: 'center',
    width: '80%',
  },
  button: {
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: 'darkgrey',
    borderWidth: 2,
    borderColor: 'white',
    alignItems: 'center',
    width: '60%',
    padding:10,
  },
  buttonText: {
    color: 'white',
  },
  signupButton: {
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: 'darkgrey',
    borderColor: 'white',
    borderWidth: 2,
    fontSize: 18,
    padding: 10,
  },
  signupButtonText: {
    color: 'white',
    textAlign: 'center',
  },
  textCenter: {
    textAlign: 'center',
    marginBottom: 5,
    color: '#fdfdfd',
  },
  roundedBorder: {
    borderRadius: '100%',
    borderWidth: 2,
    borderColor: 'white',
    margin: 10,
    backgroundColor: 'darkgrey',
    padding:30,
  },
  roundedBordertwo: {
    borderRadius: '100%',
  }
});

export default ProfilePicture;
