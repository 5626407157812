import React, { useState} from 'react';
import { View, Text, TextInput, TouchableOpacity, Image , Dimensions, ScrollView} from 'react-native';
import { boussole } from '../assets/images';
import { useNavigation } from '@react-navigation/native';

const windowWidth = Dimensions.get('window').width;
const CodeVerif = () => {
  const [code, setCode] = useState('');
  const [message, setMessage] = useState('');
  
  const handleCodeVerif = async () => {
    try {
      // Make a POST request to the login endpoint with the username and password
      const response = await fetch('https://www.gethme.com/mspr.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `code=${code}`,
      });

      //Get parameters as such 
      // if ($_SERVER['REQUEST_METHOD'] == 'POST') {
      //   // Get username and password from the request body
      //   $username = $_POST['username'];
      //   $password = $_POST['password'];
      // }


      // Parse the JSON response
      const data = await response.json();

      if (data.success) {
        // If the login was successful, set the message to "Login successful"
        setMessage('Login successful');
        navigation.navigate("ProfilePicture")
      } else {
        // If the login failed, set the message to the error message from the API
        setMessage(data.message);
      }
    } catch (error) {
      // If there was an error, set the message to the error message
      setMessage(error.message);
    }
  };
  
  const navigation = useNavigation();
  return (
    
    <View style={styles.container}>
      <Text style={styles.title}>OU EST CE QU'ON VA AUJOURD'HUI?</Text>
      <ScrollView showsVerticalScrollIndicator={false}>
      <View style={[styles.gridContainer, windowWidth > 600 ? styles.rowContainer : styles.columnContainer]}>
    <View style={styles.gridItem}>
        <Image source={boussole} style={[ windowWidth > 600 ? styles.rowImage : styles.columnImage]} resizeMode="contain" />
      
        </View>
        <View style={styles.gridItem}>
          <View style={styles.form}>
            <Text style={styles.formTitle}>TREND</Text>
            <Text style={styles.formSubtitle}>Authentification de compte</Text>
            <Text>
              Veuillez entrer le code que nous vous avons envoyé par mail/ numéro de téléphone
            </Text>
            <Text style={styles.formLabel}>Code de vérification</Text>
            <TextInput style={styles.input} placeholder="Enter verification code" value={code} onChangeText={setCode}/>
            <TouchableOpacity>
              <Text style={styles.resendLink}>Vous n'avez rien reçu?</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.submitButton}>
              <Text style={styles.submitButtonText} onPress={handleCodeVerif}>S'inscrire</Text>
            </TouchableOpacity>
            <Text>{message}</Text>
          </View>
          <TouchableOpacity style={styles.loginButton} onPress={() => navigation.navigate('Login')}>
            <Text style={styles.loginButtonText}>Connectez-vous</Text>
          </TouchableOpacity>
        </View>
      </View>
      </ScrollView>
    </View>
   
  );
};

const styles = {
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: 'darkgrey',
  },
  title: {
    fontSize: 24,
    color: '#ff9f2f',
    textAlign: 'center',
    marginBottom: 16,
  },
  content: {
    flex: 1,
    flexDirection: 'row',
  },
  left: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  right: {
    flex: 1,
    justifyContent: 'center',
  },
  image: {
    width: 500,
    height: undefined,
    aspectRatio: 1,
  },
  form: {
    padding: 16,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 25,
    backgroundColor: 'darkgrey',
    color: '#fdfdfd',
  },
  formTitle: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 8,
    color: '#fdfdfd',
  },
  formSubtitle: {
    textAlign: 'center',
    marginBottom: 8,
    color: '#fdfdfd',
  },
  formLabel: {
    paddingTop: 16,
    paddingBottom: 4,
    color: '#fdfdfd',
  },
  input: {
    marginBottom: 10,
    padding: 8,
    borderRadius: 10,
    backgroundColor: 'darkgrey',
    borderWidth: 2,
    borderColor: 'white',
    color: 'white',
  },
  resendLink: {
    color: 'white',
  },
  submitButton: {
    width: '60%',
    padding: 8,
    backgroundColor: 'darkgrey',
    color: 'white',
    borderWidth: 2,
    borderColor: 'white',
    borderRadius: 10,
    alignItems: 'center',
    marginTop: 20,
  },
  submitButtonText: {
    color: 'white',
    fontSize: 16,
  },
  gridContainer: {
    justifyContent: 'space-between',
    alignContent:'center',
    flex:1
    
  },
  rowContainer: {
    flexDirection: 'row',
  },
  columnContainer: {
    flexDirection:'column-reverse',
  },
  gridItem: {
    width: windowWidth > 600 ? '48%' : '100%',
    marginBottom: 10,
    alignItems: 'center',
  },
  loginButton: {
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: 'darkgrey',
    borderWidth: 2,
    borderColor: 'white',
    fontSize: 16,
    alignItems: 'center',
    padding: 8,
  },
  loginButtonText: {
    color: 'white',
  },
};

export default CodeVerif;
