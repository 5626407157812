import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';

const Menu = ({ items, onSelectItem }) => {
  return (
    <View style={styles.container} className="bg-red-400">
      {items.map((item, index) => (
        <TouchableOpacity
          key={index}
          style={styles.menuItem}
          onPress={() => onSelectItem(item)}
        >
          <Text style={styles.menuItemText}>{item}</Text>
        </TouchableOpacity>
      ))}
    </View>
  );
};

const styles = {
  container: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingVertical: 10,
  },
  menuItem: {
    paddingHorizontal: 20,
  },
  menuItemText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#333',
  },
};

export default Menu;
