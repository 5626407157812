import React, { useState} from 'react';
import { View, Text, TextInput, Button, StyleSheet, Image,TouchableOpacity, Platform,Modal, ScrollView } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';

import { Dimensions } from 'react-native';
import { useFonts } from 'expo-font';
import { boussole } from '../assets/images';
import { useNavigation } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';

const windowWidth = Dimensions.get('window').width;
const EntrepriseForm = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const data = ['Option 1', 'Option 2', 'Option 3', 'Option 4'];
  const [name, setName] = useState('');
  const [enterprisename, setEnterpriseName] = useState('');
  const [category, setCategory] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [rib, setRib] = useState('');
  const [message, setMessage] = useState('');
  const navigation = useNavigation();
  const [fontsLoaded] = useFonts({
    'Cut-the-crap': require('../assets/fonts/Cutthecrap.ttf'),
  });
  const handleValueChangeC = (value) => {
    setCategory(value);
    setModalVisible(false);
  };
  const selectImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status === 'granted') {
      const result = await ImagePicker.launchImageLibraryAsync({ mediaTypes: ImagePicker.MediaTypeOptions.Images });
      if (!result.cancelled) {
        setSelectedFile(result.uri);
      }
    }
  };
  const handleEnterpriseForm = async () => {
    const formData = new FormData();
formData.append('name', name);
formData.append('enterprisename', enterprisename);
formData.append('category', category);
formData.append('selectedFile', {
  uri: selectedFile,
  name: 'Logo.jpg',
  type: 'image/jpg',
});
formData.append('rib', rib);

    try {
      // Make a POST request to the login endpoint with the username and password
      const response = await fetch('https://www.gethme.com/mspr.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData,
      });

      //Get parameters as such 
      // if ($_SERVER['REQUEST_METHOD'] == 'POST') {
      //   // Get username and password from the request body
      //   $username = $_POST['username'];
      //   $password = $_POST['password'];
      // }


      // Parse the JSON response
      const data = await response.json();

      if (data.success) {
        // If the login was successful, set the message to "Login successful"
        setMessage('Login successful');
        navigation.navigate("ProfilePicture")
      } else {
        // If the login failed, set the message to the error message from the API
        setMessage(data.message);
      }
    } catch (error) {
      // If there was an error, set the message to the error message
      setMessage(error.message);
    }
  };
  const renderPicker = () => {
    if (Platform.OS === 'web') {
      // Render web-specific picker component
      return (
        
        
        <RNPickerSelect
        style={pickerSelectStyles}
        value={category}
        onValueChange={handleValueChangeC}
        items={[
          { label: 'Option 1', value: 'option1' },
          { label: 'Option 2', value: 'option2' },
          { label: 'Option 3', value: 'option3' },
        ]}
      />
      );
    } else {
      // Render app-specific picker component
      return (
        <View style={{ width:'100%',alignItems:'center' }}>
        <TouchableOpacity style={styles.input} onPress={() => setModalVisible(true)}>
        <Text style={styles.buttonText}>{category || 'Select an option'}</Text>
      </TouchableOpacity>

      <Modal visible={modalVisible} animationType="slide" transparent>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            {data.map((item) => (
              <TouchableOpacity
                key={item}
                style={styles.optionButton}
                onPress={() => handleValueChangeC(item)}
              >
                <Text style={styles.optionText}>{item}</Text>
              </TouchableOpacity>
            ))}
          </View>
        </View>
      </Modal>
       </View>
      );
    }
  };
  return (
    
    <View style={styles.container}>
    <Text style={[ windowWidth > 600 ? styles.rowtitle : styles.columntitle]}>OU EST CE QU'ON VA AUJOURD'HUI?</Text>
    <ScrollView showsVerticalScrollIndicator={false}>
    <View style={[styles.gridContainer, windowWidth > 600 ? styles.rowContainer : styles.columnContainer]}>
    <View style={styles.gridItem}>
        <Image source={boussole} style={[ windowWidth > 600 ? styles.rowImage : styles.columnImage]} resizeMode="contain" />
      </View> 
      <View style={styles.gridItem}>
        <View style={styles.form}>
          <Text style={styles.formHeading}>TREND</Text>
          <Text style={styles.buttonText}>
            Les touristes et les locaux sont de sorties...Alors partageons et profitons !
            C'est un bon moyen d'étendre ton business en proposant les biens et services sur la plateforme.
          </Text>
            <TextInput style={styles.input} placeholder="Nom" required value={name} onChangeText={setName} />
            <TextInput style={styles.input} placeholder="Nom d'entreprise" required value={enterprisename} onChangeText={setEnterpriseName}/>
            
      <TouchableOpacity style={styles.input} onPress={selectImage}>
              <Text style={styles.buttonText}>Ajouter un Logo</Text>
            </TouchableOpacity>
      {selectedFile && <Image source={{ uri: selectedFile }} style={{ width: 100, height: 100 }} />}
            <TextInput style={styles.input} placeholder="Coordonnées bancaires (RIB)" required value={rib} onChangeText={setRib}/>
            <Text style={styles.buttonText}>Catégorie:</Text>
            {renderPicker()}
            <TouchableOpacity style={styles.button} onPress={handleEnterpriseForm}>
              <Text style={styles.buttonText}>Continuer</Text>
            </TouchableOpacity>
            <Text>{message}</Text>
        </View>
        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('Login')}>
              <Text style={styles.buttonText}>Connectez-vous</Text>
            </TouchableOpacity>
      </View>
      
    </View>
    </ScrollView>
    </View>
    
  );
};
const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    height: 40,
    color: '#333',
    borderWidth: 2,
    borderColor: 'white',
    padding: 8,
    marginHorizontal: '10%',
    marginVertical:8,
    width: '80%',
    backgroundColor: 'darkgrey',
    borderRadius: 10,
  },
  inputAndroid: {
    height: 40,
    color: 'white',
    borderWidth: 2,
    borderColor: 'white',
    padding: 8,
    marginHorizontal: '10%',
    marginVertical:8,
    width: '80%',
    backgroundColor: 'darkgrey',
    borderRadius: 10,
  }
});
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    padding: 30,
    height:'100%'
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalContent: {
    backgroundColor: 'darkgrey',
    padding: 20,
    borderRadius: 5,
    width:'80%'
  },
  optionButton: {
    paddingVertical: 10,
  },
  optionText: {
    fontSize: 16,
    color:'white'
  },
  rowtitle: {
    fontSize: 50,
    color: '#ff9f2f',
    textAlign: 'center',
    padding: 20,
    fontFamily:'Cut-the-crap',
    marginBottom:30,
  },
  columntitle: {
    fontSize: 35,
    color: '#ff9f2f',
    textAlign: 'center',
    padding: 20,
    fontFamily:'Cut-the-crap',
    
  },
  gridContainer: {
    justifyContent: 'space-between',
    alignContent:'center',
    flex:1
    
  },
  rowContainer: {
    flexDirection: 'row',
  },
  columnContainer: {
    flexDirection:'column-reverse',
  },
  gridItem: {
    width: windowWidth > 600 ? '48%' : '100%',
    marginBottom: 10,
    alignItems: 'center',
  },
  columnImage: {
    width: 400,
    height: 500,
  },
  rowImage:{
    width: '80%',
    height: '80%',
  },
  heading: {
    fontSize: 30,
    fontWeight: 'bold',
    color: '#fdfdfd',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
    
  },
   
  form: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 25,
    paddingVertical: 40,
    backgroundColor: 'darkgrey',
    marginBottom: 10,
    width: '80%',
    alignItems: 'center',
  },
  formHeading: {
    fontSize: 50,
    fontWeight: 'bold',
    color: '#fdfdfd',
    textAlign: 'center',
    marginBottom: 20,
    fontFamily:'Cut-the-crap',
  },
  input: {
    margin: 10,
    padding: 10,
    borderRadius: 10,
    backgroundColor: 'darkgrey',
    borderColor: 'white',
    borderWidth: 2,
    color: 'white',
    alignItems: 'center',
    width: '80%',
  },
  select: {
    height:40,
    color: '#333',
    margin: 10,
    padding: 10,
    borderRadius: 10,
    backgroundColor: 'darkgrey',
    borderColor: 'white',
    borderWidth: 2,
    color: 'white',
    alignItems: 'center',
    width: '80%',
  },
  button: {
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: 'darkgrey',
    borderWidth: 2,
    borderColor: 'white',
    alignItems: 'center',
    width: '60%',
    padding:10,
  },
  buttonText: {
    color: 'white',
    textAlign:'center',
    width:'70%'
  },
  signupButton: {
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: 'darkgrey',
    borderColor: 'white',
    borderWidth: 2,
    fontSize: 18,
    padding: 10,
  },
  signupButtonText: {
    color: 'white',
    textAlign: 'center',
  },
  textCenter: {
    textAlign: 'center',
    marginBottom: 5,
    color: '#fdfdfd',
  },
  roundedBorder: {
    borderRadius: '100%',
    borderWidth: 2,
    borderColor: 'white',
    margin: 10,
    backgroundColor: 'darkgrey',
    padding:30,
  },
  roundedBordertwo: {
    borderRadius: '100%',
  },
  inputContainer:{
   marginBottom:80
  }
});

export default EntrepriseForm;
