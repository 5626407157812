import React from 'react';
import { View, Text, Switch, StyleSheet } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';

export default function Profile() {
  return (
    <View style={styles.container}>
      <View style={styles.containerCircle}>
        <View style={styles.caret}>
          <MaterialIcons name="keyboard-arrow-left" size={50} />
        </View>
      </View>
      <View style={styles.vipAccess}>
        <Text style={styles.title}>ACCES VIP</Text>
        <Switch style={styles.switch} />
      </View>
      <View style={styles.profileContainer}>
        <View style={styles.profileIcon}>
          <MaterialIcons name="person" size={80} />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
  },
  containerCircle: {
    alignItems: 'center',
    borderRadius: 100,
    borderWidth: 2,
    borderColor: 'black',
    padding: 10,
  },
  caret: {
    fontSize: 50,
  },
  vipAccess: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  switch: {
    transform: [{ scaleX: 1.5 }, { scaleY: 1.5 }],
  },
  profileContainer: {
    alignItems: 'center',
    marginTop: 50,
  },
  profileIcon: {
    borderWidth: 2,
    borderRadius: 100,
    borderColor: 'black',
    padding: 50,
  },
});