import { StatusBar } from 'expo-status-bar';
import { SafeAreaView, StyleSheet, Text, View } from 'react-native';
import { TailwindProvider } from 'tailwindcss-react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import HomeScreen from './screens/HomeScreen';
import Products from './screens/Products';
import Discover from './screens/Discover';
import Form from './screens/Form';
import CategoryPage from './screens/CategoryPage';
import HomeForm from './screens/HomeForm';
// import Accueil from './wisdom/pages/Accueil';
// import Boutique from './wisdom/pages/Boutique';
// import About from './wisdom/pages/About';
import Trend from './Trendapp/pages/Trend';
import ModePro from './Trendapp/pages/ModePro';
import ParcoursMystere from './Trendapp/pages/ParcousMystere';
import Page from './Trendapp/pages/page';
import CodeVerif from './Trendapp/pages/CodeVerif';
import Login from './Trendapp/pages/Login';
import Register from './Trendapp/pages/Register';
import ProfilePicture from './Trendapp/pages/ProfilePicture';
import ComptePro from './Trendapp/pages/ComptePro';
import EntrepriseForm from './Trendapp/pages/EntrepriseForm';
import Dashboard from './Trendapp/pages/Dashboard';
import Interets from './Trendapp/pages/Interets';
import Map from './Trendapp/pages/Map';
import Tab from './Trendapp/pages/Tab';
import Profile from './Trendapp/pages/Profile';




const Stack = createNativeStackNavigator();

export default function App() {
  return (
    
    <TailwindProvider>
      <NavigationContainer>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
      {/* <Stack.Screen name="HomeForm" component={HomeForm} /> */}
      
      <Stack.Screen name="Dashboard" component={Dashboard} />
      <Stack.Screen name="Profile" component={Profile} />
      <Stack.Screen name="Interets" component={Interets} />
      <Stack.Screen name="Map" component={Map} />
      <Stack.Screen name="Page" component={Page} />
      <Stack.Screen name="ParcoursMystere" component={ParcoursMystere} />
      <Stack.Screen name="ModePro" component={ModePro} />
      <Stack.Screen name="Tab" component={Tab} />
      <Stack.Screen name="ComptePro" component={ComptePro} />
      <Stack.Screen name="ProfilePicture" component={ProfilePicture} />
      <Stack.Screen name="Register" component={Register} />
      <Stack.Screen name="Trend" component={Trend} />
      <Stack.Screen name="Login" component={Login} />
      <Stack.Screen name="CodeVerif" component={CodeVerif} />
      <Stack.Screen name="EntrepriseForm" component={EntrepriseForm} />
      
      {/* <Stack.Screen name="Accueil" component={Accueil} />
      <Stack.Screen name="Boutique" component={Boutique} />
      <Stack.Screen name="About" component={About} />
      <Stack.Screen name="HomeScreen" component={HomeScreen} />
      <Stack.Screen name="Form" component={Form} />
      <Stack.Screen name="CategoryPage" component={CategoryPage} />
      <Stack.Screen name="Discover" component={Discover} />
      <Stack.Screen name="Products" component={Products} /> */}
      </Stack.Navigator>
      </NavigationContainer>
   </TailwindProvider>
  );
}





