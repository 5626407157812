import React, { useEffect, useLayoutEffect, useState } from 'react';
import { View, Text, FlatList, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Menu from '../components/Menu';

const CategoryPage = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch categories from an API or database
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://gethme.com/api.html');
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);
  const menuItems = ['Back','Home', 'Connect'];

  const handleSelectItem = (item) => {
    if(item=='Back'){
    
    navigation.goBack();
    }
    if(item=='Home'){
    
        navigation.navigate("HomeScreen");
        }
    else{
      navigation.navigate("Form");
    }
   // console.log('Selected item:', item);
    // Handle the selected item here
  };
  const navigation = useNavigation();

  useLayoutEffect(()=>{
      navigation.setOptions({
          headerShown: false,
      });
  },[]);
  const renderItem = ({ item }) => (
    <TouchableOpacity style={styles.categoryItem} className="bg-red-300" onPress={() => navigation.navigate("HomeForm")}>
      <Text style={styles.categoryTitle} >{item.type}</Text>
    </TouchableOpacity>
  );

  return (
    <View style={styles.container} className="bg-red-400">
        <View className="mt-14">
      <Menu items={menuItems} onSelectItem={handleSelectItem}  />
    </View>
      <Text style={styles.pageTitle} className="mt-14">Categories</Text>
      <FlatList
        data={categories}
        numColumns={2}
        renderItem={renderItem}
        keyExtractor={(item) => item.id.toString()}
        columnWrapperStyle={styles.container}
      />
    </View>
  );
};

const styles = {
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  pageTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  categoryItem: {
    borderRadius: 8,
    padding: 16,
    margin: 10,
  },
  categoryTitle: {
    fontSize: 16,
    fontWeight: 'bold',
  },
};

export default CategoryPage;
