import React from 'react';
import { View, Text, TextInput, TouchableOpacity, Image, StyleSheet, ScrollView } from 'react-native';
import { boussole } from '../assets/images';
import { Dimensions } from 'react-native';
import { useFonts } from 'expo-font';

const windowWidth = Dimensions.get('window').width;

const Trend = () => {
  const [fontsLoaded] = useFonts({
    'Cut-the-crap': require('../assets/fonts/Cutthecrap.ttf'),
  });
  return (
    <View style={styles.container}>
      <Text style={[ windowWidth > 600 ? styles.rowtitle : styles.columntitle]}>OU EST CE QU'ON VA AUJOURD'HUI?</Text>
      <ScrollView showsVerticalScrollIndicator={false}>
      <View style={[styles.gridContainer, windowWidth > 600 ? styles.rowContainer : styles.columnContainer]}>
        <View style={styles.gridItem}>
        <View style={styles.form}>
            <Text style={styles.formHeading}>TREND</Text>
            <Text style={styles.signupButtonText}>Authentification de compte</Text>
            <Text style={styles.signupButtonText}>Veuillez entrer le code que nous vous avons envoyé par mail/ numéro de téléphone</Text>
            <Text style={styles.signupButtonTextc}>Code de vérification</Text>
            <TextInput style={styles.input} placeholderTextColor="white" placeholder="Code de vérification" />
            <TouchableOpacity>
              <Text style={styles.signupButtonText}>Vous n'avez rien reçu ?</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.button}>
              <Text style={styles.buttonText}>S'inscrire</Text>
            </TouchableOpacity>
          </View>
          <Text></Text>
          <View style={{ padding:20, width:'100%', alignItems:'center' }}>
          <TouchableOpacity style={[styles.button, styles.loginButton]}>
            <Text style={styles.buttonText}>Connectez-vous</Text>
          </TouchableOpacity>
          </View>
        </View>
        <View style={styles.gridItem}>
          <Image source={boussole} style={[ windowWidth > 600 ? styles.rowImage : styles.columnImage]} resizeMode="contain" />
        </View>
      </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    padding: 30,
  },
  rowtitle: {
    fontSize: 50,
    color: '#ff9f2f',
    textAlign: 'center',
    padding: 20,
    fontFamily:'Cut-the-crap',
  },
  columntitle: {
    fontSize: 35,
    color: '#ff9f2f',
    textAlign: 'center',
    padding: 20,
    fontFamily:'Cut-the-crap',
  },
  gridContainer: {
    justifyContent: 'space-between',
    alignContent:'center',
    flex:1
    
  },
  rowContainer: {
    flexDirection: 'row',
  },
  columnContainer: {
    flexDirection: 'column',
  },
  gridItem: {
    width: windowWidth > 600 ? '48%' : '100%',
    marginBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  columnImage: {
    width: 400,
    height: 500,
  },
  rowImage:{
    width: '80%',
    height: '80%',
  },
  heading: {
    fontSize: 30,
    fontWeight: 'bold',
    color: '#fdfdfd',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
    
  },
   
  form: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 25,
    paddingVertical: 40,
    backgroundColor: 'darkgrey',
    marginBottom: 10,
    width: '80%',
    alignItems: 'center',
  },
  formHeading: {
    fontSize: 50,
    fontWeight: 'bold',
    color: '#fdfdfd',
    textAlign: 'center',
    marginBottom: 20,
    fontFamily:'Cut-the-crap',
  },
  input: {
    marginBottom: 20,
    padding: 10,
    borderRadius: 10,
    backgroundColor: 'darkgrey',
    borderColor: 'white',
    borderWidth: 2,
    color: 'white',
    alignItems: 'center',
    width: '60%',
  },
  button: {
    borderRadius: 10,
    marginTop: 80,
    backgroundColor: 'darkgrey',
    borderWidth: 2,
    borderColor: 'white',
    alignItems: 'center',
    width: '60%',
    padding:10,
  },
  buttonText: {
    color: 'white',
  },
  signupButton: {
    borderRadius: 10,
    marginTop: 20,
    backgroundColor: 'darkgrey',
    borderColor: 'white',
    borderWidth: 2,
    fontSize: 18,
    padding: 10,
  },
  
  signupButtonText: {
    color: 'white',
    textAlign: 'center',
  },
  signupButtonTextc: {
    color: 'white',
    textAlign: 'center',
    marginTop: 60,
  },
  
});

export default Trend;
