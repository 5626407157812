import React, { useState, useLayoutEffect } from 'react';
import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';



const Form = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigation = useNavigation();

    useLayoutEffect(()=>{
        navigation.setOptions({
            headerShown: false,
        });
    },[]);

  const handleLogin = async () => {
    try {
      // Make a POST request to the login endpoint with the username and password
      const response = await fetch('https://www.gethme.com/mspr.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `username=${username}&password=${password}`,
      });

      //Get parameters as such 
      // if ($_SERVER['REQUEST_METHOD'] == 'POST') {
      //   // Get username and password from the request body
      //   $username = $_POST['username'];
      //   $password = $_POST['password'];
      // }


      // Parse the JSON response
      const data = await response.json();

      if (data.success) {
        // If the login was successful, set the message to "Login successful"
        setMessage('Login successful');
        navigation.navigate("HomeForm")
      } else {
        // If the login failed, set the message to the error message from the API
        setMessage(data.message);
      }
    } catch (error) {
      // If there was an error, set the message to the error message
      setMessage(error.message);
    }
  };

  return (
    <View style={styles.container} clasName="bg-red-300">
      <TextInput
        style={styles.input}
        placeholder="Username"
        value={username}
        onChangeText={setUsername}
      />
      <TextInput
        style={styles.input}
        placeholder="Password"
        value={password}
        onChangeText={setPassword}
        secureTextEntry={true}
      />
      <TouchableOpacity style={styles.button} onPress={handleLogin}>
        <Text style={styles.buttonText}>Login</Text>
      </TouchableOpacity>
      <Text style={styles.message}>{message}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    padding: 8,
    margin: 8,
    width: '80%',
  },
  button: {
    backgroundColor: '#2196f3',
    padding: 10,
    borderRadius: 8,
    margin: 8,
  },
  buttonText: {
    color: '#fff',
  }
}); 

export default Form;
