import React, { useState, useEffect, useLayoutEffect } from 'react';
import { View, Text, TextInput,StyleSheet, TouchableOpacity, Alert, Button, ScrollView, Image } from 'react-native';
import {Picker} from '@react-native-picker/picker';
import { useNavigation } from '@react-navigation/native';
import Menu from '../components/Menu';
import * as ImagePicker from 'expo-image-picker';

//import Geolocation from '@react-native-community/geolocation';

const HomeForm = () => {
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [landlordName, setLandlordName] = useState('');
  const [price, setPrice] = useState('');
  const [phone, setPhone] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [kitchen, setKitchen] = useState('');
  const [pallor, setPallor] = useState('');
  const [toilets, setToilets] = useState('');
  const [comment, setComment] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [message, setMessage] = useState('');

//   useEffect(() => {
//     getCurrentLocation();
//   }, []);

//   const getCurrentLocation = () => {
//     Geolocation.getCurrentPosition(
//       position => {
//         const { latitude, longitude } = position.coords;
//         setLatitude(latitude.toString());
//         setLongitude(longitude.toString());
//       },
//       error => {
//         Alert.alert('Error', 'Failed to get current location.');
//       },
//       { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
//     );
//   };
const selectImage = async () => {
  const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
  if (status === 'granted') {
    const result = await ImagePicker.launchImageLibraryAsync({ mediaTypes: ImagePicker.MediaTypeOptions.Images });
    if (!result.cancelled) {
      setSelectedFile(result.uri);
    }
  }
};
const selectImage1 = async () => {
  const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
  if (status === 'granted') {
    const result = await ImagePicker.launchImageLibraryAsync({ mediaTypes: ImagePicker.MediaTypeOptions.Images });
    if (!result.cancelled) {
      setSelectedFile1(result.uri);
    }
  }
};
const selectImage2 = async () => {
  const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
  if (status === 'granted') {
    const result = await ImagePicker.launchImageLibraryAsync({ mediaTypes: ImagePicker.MediaTypeOptions.Images });
    if (!result.cancelled) {
      setSelectedFile2(result.uri);
    }
  }
};
const selectImage3 = async () => {
  const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
  if (status === 'granted') {
    const result = await ImagePicker.launchImageLibraryAsync({ mediaTypes: ImagePicker.MediaTypeOptions.Images });
    if (!result.cancelled) {
      setSelectedFile3(result.uri);
    }
  }
};

  
const handleSubmit = async () => {
  const formData = new FormData();
    formData.append('latitude', latitude);
    formData.append('longitude', longitude);
    formData.append('landlordName', landlordName);
    formData.append('price', price);
    formData.append('phone', phone);
    formData.append('comment', comment);
    formData.append('propertyType', propertyType);
    formData.append('bedrooms', bedrooms);
    formData.append('kitchen', kitchen);
    formData.append('pallor', pallor);
    formData.append('toilets', toilets);
    formData.append('selectedFile', {
      uri: selectedFile,
      name: 'profile.jpg',
      type: 'image/jpg',
    });
    formData.append('selectedFile1', {
      uri: selectedFile1,
      name: 'profile1.jpg',
      type: 'image/jpg',
    });
    formData.append('selectedFile2', {
      uri: selectedFile2,
      name: 'profile2.jpg',
      type: 'image/jpg',
    });
    formData.append('selectedFile3', {
      uri: selectedFile3,
      name: 'profile3.jpg',
      type: 'image/jpg',
    });
  // Perform form submission logic here
  // You can access all the form values from the state variables
  try {
    // Make a POST request to the login endpoint with the form data
    const response = await fetch('https://www.gethme.com/mobilecollection.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      body: formData,
    });

    // Parse the JSON response
    const data = await response.json();

    if (data.success) {
      // If the submission was successful, set the message to "Submission successful"
      setMessage('Submission successful');
      alert(message);
      navigation.navigate('HomeForm');
    } else {
      // If the submission failed, set the message to the error message from the API
      setMessage(data.message);
      alert(data.message); // Display the error message received from the API
    }
  } catch (error) {
    alert(error.message);
    // If there was an error, set the message to the error message
    setMessage(error.message);
  }
};

  const handleValueChangeK = (value) => {
    setKitchen(value);
  };
  const handleValueChangeB = (value) => {
    setBedrooms(value);
  };
  const handleValueChangeP = (value) => {
    setPallor(value);
  };
  const handleValueChangeT = (value) => {
    setToilets(value);
  };
  const handleValueChange = (value) => {
    setPropertyType(value);
  };
  const menuItems = ['Home', 'Connect'];

  const handleSelectItem = (item) => {
    if(item=='Back'){
    navigation.goBack();
    }
    if(item=='Home'){
    
        navigation.navigate("HomeScreen");
        }
    else{
      navigation.navigate("Form");
    }
   // console.log('Selected item:', item);
    // Handle the selected item here
  };
  const navigation = useNavigation();

  useLayoutEffect(()=>{
      navigation.setOptions({
          headerShown: false,
      });
  },[]);
  return (
    <ScrollView className="bg-red-400">
    <View  style={styles.container}>
      
        <View className="mt-14">
      <Menu items={menuItems} onSelectItem={handleSelectItem}  />
    </View>
          <Text style={styles.pageTitle} className="mt-2">Post your Findings </Text>
      <Text className="mt-2">You made 15 posts already</Text>
       <View style={styles.pickerContainer}>
        {/* <Text>Latitude</Text> */}
      <TextInput
      style={styles.input}
        value={latitude}
        onChangeText={setLatitude}
        placeholder="Enter latitude"
      />

      {/* <Text>Longitude</Text> */}
      <TextInput
      style={styles.input}
        value={longitude}
        onChangeText={setLongitude}
        placeholder="Enter longitude"
      />

      {/* <Text>Landlord's Name</Text> */}
      <TextInput
      style={styles.input}
        value={landlordName}
        onChangeText={setLandlordName}
        placeholder="Enter landlord's name"
      />

      {/* <Text>Price</Text> */}
      <TextInput
      style={styles.input}
        value={price}
        onChangeText={setPrice}
        placeholder="Enter price"
      />

      
      <TextInput
      style={styles.input}
        value={phone}
        onChangeText={setPhone}
        placeholder="Enter phone"
      />
       <TextInput
      style={styles.input}
        value={comment}
        onChangeText={setComment}
        placeholder="Enter comment"
      />
      <Text>House Type:</Text>
      <Picker
        style={styles.select}
        selectedValue={propertyType}
        onValueChange={handleValueChange}
      >
        <Picker.Item label="Appartment" value="Appartment" />
        <Picker.Item label="Studio" value="Studio" />
        <Picker.Item label="Room" value="Room" />
        <Picker.Item label="Guest House" value="Guest House" />
      </Picker>
      <Text>Bedrooms:</Text>
      <Picker
       style={styles.select}
        selectedValue={bedrooms}
        onValueChange={handleValueChange}
      >
        <Picker.Item label="1" value="1" />
        <Picker.Item label="2" value="2" />
        <Picker.Item label="3" value="3" />
        <Picker.Item label="4" value="4" />
      </Picker>
      <Text>Pallors:</Text>
      <Picker
       style={styles.select}
        selectedValue={pallor}
        onValueChange={handleValueChangeP}
      >
        <Picker.Item label="1" value="1" />
        <Picker.Item label="2" value="2" />
        <Picker.Item label="None" value="None" />
      </Picker>
      <Text>Toilets:</Text>
      <Picker
       style={styles.select}
        selectedValue={toilets}
        onValueChange={handleValueChangeT}
      >
        <Picker.Item label="1" value="1" />
        <Picker.Item label="2" value="2" />
        <Picker.Item label="3" value="3" />
      </Picker>
      <Text>Kitchen:</Text>
         <Picker
       style={styles.select}
        selectedValue={kitchen}
        onValueChange={handleValueChange}
      >
        <Picker.Item label="1" value="1" />
        <Picker.Item label="2" value="2" />
        <Picker.Item label="None" value="None" />
      </Picker>
      {/* <Text>Type</Text> */}
      <Button title="Select Main image" onPress={selectImage} />
      {selectedFile && <Image source={{ uri: selectedFile }} style={{ width: 200, height: 200 }} />}

      <Button title="Select Image1" onPress={selectImage1} />
      {selectedFile1 && <Image source={{ uri: selectedFile1 }} style={{ width: 200, height: 200 }} />}

      <Button title="Select Image2" onPress={selectImage2} />
      {selectedFile2 && <Image source={{ uri: selectedFile2 }} style={{ width: 200, height: 200 }} />}

      <Button title="Select Image3" onPress={selectImage3} />
      {selectedFile3 && <Image source={{ uri: selectedFile3 }} style={{ width: 200, height: 200 }} />}


      </View>
      <TouchableOpacity style={styles.button} className="bg-white mb-10 mt-5"  onPress={handleSubmit}>
        <Text className=" text-red-400">Submit</Text>
      </TouchableOpacity>
      
    </View>
    </ScrollView>
  );
};
const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
    },
    
    input: {
      borderWidth: 1,
      borderColor: '#ccc',
      padding: 8,
      margin: 8,
      width: '80%',
      backgroundColor: 'white',
      borderRadius: 4,
    },
    button: {
      padding: 10,
      borderRadius: 8,
      margin: 8,
    },
    buttonText: {
      color: '#fff',
    },
    pageTitle: {
        fontSize: 24,
        fontWeight: 'bold',
       
      },
      pickerContainer: {
     // Set the desired width for the picker
        borderColor: '#ccc',
        paddingHorizontal: 16,
        width:'90%',
        
      },
      select: {
        width: 200,
        height: 40,
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 5,
        marginBottom: 20,
      },
      selectedValue: {
        fontSize: 16,
      },
  }); 
 
export default HomeForm;
