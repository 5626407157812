import React from 'react';
import { View, Text, StyleSheet,TouchableOpacity, Dimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useFonts } from 'expo-font';
import { useNavigation } from '@react-navigation/native';

const windowWidth = Dimensions.get('window').width;

const Interets = () => {
  const navigation = useNavigation();
  const [fontsLoaded] = useFonts({
    'Cut-the-crap': require('../assets/fonts/Cutthecrap.ttf'),
  });
  return (
    <View style={styles.container}>
      <View style={styles.topBar}>
        <View style={styles.containerCircle}>
        <TouchableOpacity onPress={() => navigation.goBack()}>
          <Ionicons name="chevron-back" style={[ windowWidth > 600 ? styles.rowicon : styles.columnicon]} />
          </TouchableOpacity>
        </View>
        <View style={styles.containerTitle}>
        <TouchableOpacity onPress={() => navigation.navigate('Dashboard')}>
          <Text style={styles.title}>TREND</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.position}>
        <Text style={styles.positionText}>MES CENTRES D'INTERETS</Text>
      </View>
      <View style={styles.content}>
        <View style={styles.aime}>
          <Text style={styles.aimeText}>CE QUE J'AIME</Text>
        </View>
        <View style={styles.gridContainer}>
          {renderGridItems()}
          </View>
          <View style={{ alignItems:'center', margin:30 }}>
          <View style={styles.check}>
          <Ionicons name="checkmark" style={[ windowWidth > 600 ? styles.rowicon : styles.columnicon]} color="white" />
          </View>
          </View>
      </View>
    </View>
  );
};

const renderGridItems = () => {
  const gridItems = [];
  for (let i = 0; i < 6; i++) {
    gridItems.push(
      <View key={i} style={styles.gridItem}></View>
    );
  }
  return gridItems;
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  rowicon: {
    marginRight: 10,
    fontSize:45,
  },
  columnicon: {
    marginRight: 10,
    fontSize:35,
  },
  topBar: {
    flexDirection: 'row',
    backgroundColor: 'orange',
    paddingHorizontal: 20,
    paddingTop:50,
    paddingBottom:10,
    borderBottomColor:'gray',
    borderBottomWidth:1
  },
  containerCircle: {
    height: '100%',
    width: '25%',
  },
  containerTitle: {
    height: '100%',
    width: '50%',
    alignItems: 'center',
  },
  title: {
    color: 'white',
    fontSize: 40,
    fontFamily:'Cut-the-crap',
  },
  position: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: 'orange',
    color: 'white',
    paddingHorizontal: 30,
    paddingVertical:15,
    shadowColor: 'orange',
    shadowOffset: { width: 0, height: 10 },
    shadowOpacity: 0.5,
    shadowRadius: 20,
    marginBottom: 30,
  },
  positionText: {
    fontSize: 30,
    fontFamily:'Cut-the-crap',
    color:'white'
  },
  content: {
    padding: 20,
  },
  aime: {
    marginLeft: 20,
  },
  aimeText: {
    fontSize: 30,
    color:'gray',
    fontFamily:'Cut-the-crap',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: 10,
    marginTop: 20,
  },
  gridItem: {
    flex: 1,
    padding: 25,
    textAlign: 'center',
    borderWidth: 1,
    borderColor: 'black',
    marginVertical:5
  },
  check: {
    backgroundColor: 'orange',
    borderRadius: 100,
    width: 60,
    height: 60,
    alignItems: 'center',
    justifyContent: 'center',
  },
 
});

export default Interets;
