import React, { useState } from 'react';
import { View, Text, StyleSheet,TouchableOpacity, Dimensions } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useFonts } from 'expo-font';
import { useNavigation } from '@react-navigation/native';

const windowWidth = Dimensions.get('window').width;

export default function Tab() {
  const navigation = useNavigation();
  const [activeTab, setActiveTab] = useState(0);
  const [fontsLoaded] = useFonts({
    'Cut-the-crap': require('../assets/fonts/Cutthecrap.ttf'),
  });
  const handleTabPress = (index) => {
    setActiveTab(index);
  };

  return (
    <View style={styles.container}>
      <View style={styles.topBar}>
        <View style={styles.containerTitle}>
        <TouchableOpacity onPress={() => navigation.navigate('Dashboard')}>
          <Text style={styles.title}>TREND</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.containerStar}>
          <Ionicons name="chatbubble-outline" style={[ windowWidth > 600 ? styles.rowicon : styles.columnicon]} />
          <Ionicons name="notifications-outline" style={[ windowWidth > 600 ? styles.rowicon : styles.columnicon]} />
          <Ionicons name="settings-outline" style={[ windowWidth > 600 ? styles.rowicon : styles.columnicon]} />
        </View>
      </View>
      <View style={styles.act}>
        <View style={styles.mes}>
        <TouchableOpacity onPress={() => navigation.navigate('Tab')}>
          <Text style={styles.navLink}>MES ACTIVITÉS</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => navigation.navigate('ParcoursMystere')}>
          <Text style={styles.navLink}>PARCOURS</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => navigation.navigate('Dashboard')}>
          <Text style={styles.navLink}>BOUTIQUE</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.tab}>
        <TouchableOpacity
          style={[styles.tabLink, activeTab === 0 && styles.activeTabLink]}
          onPress={() => handleTabPress(0)}
        >
          <Text style={styles.tabText}>En Cours</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.tabLink, activeTab === 1 && styles.activeTabLink]}
          onPress={() => handleTabPress(1)}
        >
          <Text style={styles.tabText}>À suivre</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.tabLink, activeTab === 2 && styles.activeTabLink]}
          onPress={() => handleTabPress(2)}
        >
          <Text style={styles.tabText}>Déjà passées</Text>
        </TouchableOpacity>
      </View>
      <View style={styles.tabContent}>
        {activeTab === 0 && <Text style={styles.tabText}>1</Text>}
        {activeTab === 1 && <Text style={styles.tabText}>2</Text>}
        {activeTab === 2 && <Text style={styles.tabText}>3</Text>}
      </View>
      </View>
    </View>
  );
} 

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    padding:30,
  },
  rowicon: {
    marginRight: 10,
    fontSize:45,
  },
  columnicon: {
    marginRight: 10,
    fontSize:35,
  },
  topBar: {
    flexDirection: 'row',
    marginTop:20,
  },
  containerTitle: {
    width: '80%',
  },
  title: {
    color: 'orange',
    fontSize: 40,
    fontFamily:'Cut-the-crap',
  },
  containerStar: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  icon: {
    fontSize: 25,
    color: 'black',
    margin: 5,
  },
  act: {
    
    marginTop: 50,
  },
  mes: {
    backgroundColor: 'rgb(178, 172, 184)',
    borderRadius: 25,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    fontSize: 25,
    color: 'white',
    boxShadow: 'inset 4px 4px 5px rgba(0, 0, 0, 0.5)',
    padding: 2,
    marginBottom: 50,
  },
  navLink: {
    margin: 10,
    color:'white',
    fontFamily:'Cut-the-crap',
    fontSize:20,
  },
  tab: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  tabLink: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: 10,
    borderBottomWidth: 2,
    borderBottomColor: 'transparent',
  },
  activeTabLink: {
    borderBottomColor: 'orange',
  },
  tabText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  tabContent: {
    marginTop: 20,
    alignItems:'center'
  },
});
